import Vue from 'vue';
import Router from 'vue-router';

import store from './store';
import decode from 'jwt-decode';

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: `${process.env.VUE_APP_BASE_URL}`, 
  routes : [
    { path: '*', redirect: { name: 'inicio' } },
    {
      path:'/login',
      name:'login',
      meta: {layout: 'no-sidebar'},
      //component: () => import('./views/Login.vue'),
      component: () => import('./views/State.vue'),
      props: route => ({ state: route.query.state })
    },
    {
      path:'/inicio',
      name:'inicio',
      component: () => import('./views/Inicio.vue'),
      meta: { requireAuth: true}
    },
    {
      path: '/perfil',
      name: 'perfil',
      component: () => import('./views/Perfil.vue'),
      meta: { requireAuth: true }
    },
    {
      path: '/cambiarPass',
      name: 'cambiarPass',
      component: () => import('./views/CambiarPass.vue'),
      meta: { requireAuth: true }
    },  
    {
      path: '/acerca',
      name: 'acerca',
      component: () => import('./views/Acerca.vue'),
      meta: { requireAuth: true }
    },   
    {
      path: '/orden',
      name: 'orden',
      component: () => import('./views/Orden.vue'),
      meta: { requireAuth: true}
    },
    {
      path: '/ordenes',
      name: 'ordenes',
      component: () => import('./views/Ordenes.vue'),
      meta: { requireAuth: true}
    },
    {
      path: '/pendientes',
      name: 'pendientes',
      component: () => import('./views/Pendientes.vue'),
      meta: { requireAuth: true}
    },
    {
      path: '/facturacion',
      name: 'facturacion',
      component: () => import('./views/Facturacion.vue'),
      meta: { requireAuth: true}
    },
    {
      path: '/reportes',
      name: 'reportes',
      component: () => import('./views/Reportes.vue'),
      meta: { requireAuth: true}
    },
    {
      path: '/personas',
      name: 'personas',
      component: () => import('./views/Personas.vue'),
      meta: { requireAuth: true, adminAuth:true}
    },
    {
      path: '/usuarios',
      name: 'usuarios',
      component: () => import('./views/Usuarios.vue'),
      meta: { requireAuth: true, adminAuth:true}
    },
    {
      path: '/configuracion',
      name: 'configuracion',
      component: () => import('./views/Configuracion.vue'),
      meta: { requireAuth: true, adminAuth:true}
    },
    {
      path: '/reparticiones',
      name: 'reparticiones',
      component: () => import('./views/Reparticiones.vue'),
      meta: { requireAuth: true, adminAuth:true}
    },
    {
      path: '/subreparticiones',
      name: 'subreparticiones',
      component: () => import('./views/Subreparticiones.vue'),
      meta: { requireAuth: true, adminAuth:true}
    },
    {
      path: '/rubros',
      name: 'rubros',
      component: () => import('./views/Rubros.vue'),
      meta: { requireAuth: true, adminAuth:true}
    },
    {
      path: '/aranceles',
      name: 'aranceles',
      component: () => import('./views/Aranceles.vue'),
      meta: { requireAuth: true, adminAuth:true}
    },
    {
      path: '/bancos',
      name: 'bancos',
      component: () => import('./views/Bancos.vue'),
      meta: { requireAuth: true, adminAuth:true}
    }
  ]

})

router.beforeEach((to, from, next) => {

  const rutaProtegida = to.matched.some(record => record.meta.requireAuth);
  const rutaAdmin = to.matched.some(record => record.meta.adminAuth);  
  const rutaCaja = to.matched.some(record => record.meta.cajaAuth);  
  const rutaVentanilla = to.matched.some(record => record.meta.ventanillaAuth);  
  const rutaCajaVentanilla = to.matched.some(record => record.meta.cajaVentanillaAuth);  

  if(rutaProtegida && store.state.token === ''){  
    next({name: 'login'});
  }else if(rutaAdmin){
    const user = decode(localStorage.getItem('token'));
    let rol = user.rol;
    if(Number(rol)===1){
      next();
    }else{
      next({name: 'inicio'});
    }    
  }else if(rutaCaja){
    const user = decode(localStorage.getItem('token'));
    let rol = user.rol;
    if(Number(rol===2)){
      next();
    }else{
      next({name: 'inicio'});
    } 
  }else if(rutaVentanilla){
    const user = decode(localStorage.getItem('token'));
    let rol = user.rol;
    if(Number(rol===3)){
      next();
    }else{
      next({name: 'inicio'});
    } 
  }else if(rutaCajaVentanilla){
      const user = decode(localStorage.getItem('token'));
      let rol = user.rol;
      if(Number(rol===4)){
        next();
      }else{
        next({name: 'inicio'});
      }   
  }else{
    next();  
  }

});

export default router;
