import Vue from 'vue';
import Vuex from 'vuex';
import router from './router';
import decode from 'jwt-decode';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(Vuex, axios, VueAxios);

export default new Vuex.Store({
  state: {
    token: '',
    usuarioDB: ''
  },
  mutations: {
    obtenerUsuario(state, payload){  
       
      state.token = payload;
      if(payload === ''){
        state.usuarioDB = '';
      }else{
        state.usuarioDB = decode(payload);
        if(router.history.current.name=='login'){
          router.push({name: 'inicio'});
        }
      }
    },
    Sucursal(state, payload){
      state.sucursal = payload;
    }
  },
  actions: {
    guardarUsuario({commit}, payload){
      localStorage.setItem('token', payload);
      commit('obtenerUsuario', payload)
    },
    cerrarSesion({commit}){
      commit('obtenerUsuario', '');
      localStorage.removeItem('token');
      //router.push({name: 'login'});
      window.location.href = process.env.VUE_APP_URL_LOGOUT;      
    },
    leerToken({commit}){
      const token = localStorage.getItem('token');
      if(token){
        commit('obtenerUsuario', token);
      }else{
        commit('obtenerUsuario', '');
      }
    }
  },
  getters:{
    estaActivo: state => !!state.token
  }
})
