import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueSweetalert2 from 'vue-sweetalert2';
import HighchartsVue from 'highcharts-vue';
import 'sweetalert2/dist/sweetalert2.min.css';

import BootstrapVue from 'bootstrap-vue'; 
Vue.use(BootstrapVue);

import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);
import VueSimpleAlert from "vue-simple-alert";
Vue.use(VueSimpleAlert);

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(HighchartsVue);

import 'font-awesome/css/font-awesome.min.css';
import 'vue-loading-overlay/dist/vue-loading.css';
import './assets/main.css';

import Default from './layouts/Default.vue';
import NoSidebar from './layouts/NoSidebar.vue';

Vue.component('default-layout', Default);
Vue.component('no-sidebar-layout', NoSidebar);

axios.defaults.baseURL = process.env.VUE_APP_URL;

//libreria para combo con buscador
import 'vue-search-select/dist/VueSearchSelect.css';

//interceptar las petiuciones y ver si tiene token

axios.interceptors.response.use((response) => {
  if(response.status == 401 || response.status == 403) {
    localStorage.removeItem('token');
    window.location.href = `${process.env.VUE_APP_BASE_URL}`;
  }
  return response;
}, (error) => {
  console.log(error);
  if(error.response.status == 401 || error.response.status == 403) {
    localStorage.removeItem('token');
    window.location.href = `${process.env.VUE_APP_BASE_URL}`;
  }
});

//envia token para todas las peticiones
axios.interceptors.request.use(
  config => {
      if (localStorage.getItem("token")) {       
          config.headers.token = localStorage.getItem("token");
      }
      return config;
  },
  function(err) {
      console.log("No hay token desde el interceptor" + err);
  }
);


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
