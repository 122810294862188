<template>
  <div id="app">      
   
    <component :is="layout">
      <router-view></router-view>
    </component>

  </div>
</template>

<script>
  const default_layout = "default";
  export default {
    computed: {
      layout(){
        return (this.$route.meta.layout || default_layout) + '-layout';
      }
    }
  }

</script>

<style>
html {
  position: relative;
  min-height: 100%;
}
</style>
