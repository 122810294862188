<template>
  <div class="fondo"> 
    <slot />
  </div>    
 
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'NoSidebar',
  computed: {
    ...mapGetters(["estaActivo"])
  },
  methods: {
    ...mapActions(["cerrarSesion", "leerToken"])       
  },  
  created() {
    this.leerToken();
  }
};
</script>

<style scoped>

.fondo {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  background-image: url("../assets/fondo.jpg");
  background-repeat: repeat;
  background-attachment: fixed;
  background-size: cover;
}
</style>
