<template>
  <div class="fondo" >
  
      <nav class="navbar navbar-expand-lg navbar-dark bg-primary container-fluid p-3">
        <b-navbar-brand to="/inicio"><i :class="icon"></i> {{title}}</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse" v-if="estaActivo"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav v-if="estaActivo">
         
          <b-navbar-nav class="me-auto" v-if="estaActivo && ($store.state.usuarioDB.rol==1) ">    
            <b-nav-item to="/personas" :active="$route.path === '/personas'">
              <i class="fa fa-users"></i> Personas
            </b-nav-item>       
            <b-nav-item to="/usuarios" :active="$route.path === '/usuarios'">
              <i class="fa fa-user"></i> Usuarios
            </b-nav-item>
            <b-nav-item to="/configuracion" :active="$route.path === '/configuracion'">
              <i class="fa fa-cog"></i> Configuración
            </b-nav-item>
            <b-nav-item to="/bancos" :active="$route.path === '/bancos'">
              <i class="fa fa-bank"></i> Bancos
            </b-nav-item>  
            <b-nav-item to="/reparticiones" :active="$route.path === '/reparticiones'">
              <i class="fa fa-list-alt"></i> Reparticiones
            </b-nav-item>  
            <b-nav-item to="/subreparticiones" :active="$route.path === '/subreparticiones'">
              <i class="fa fa-paste"></i> Sub Reparticiones
            </b-nav-item>  
            <b-nav-item to="/rubros" :active="$route.path === '/rubros'">
              <i class="fa fa-tag"></i> Rubros
            </b-nav-item>  
            <b-nav-item to="/aranceles" :active="$route.path === '/aranceles'">
              <i class="fa fa-file"></i> Aranceles
            </b-nav-item>          
          </b-navbar-nav>

          <b-navbar-nav class="me-auto" v-if="estaActivo && ($store.state.usuarioDB.rol==2) ">            
            <b-nav-item to="/orden" :active="$route.path === '/orden'">
              <i class="fa fa-tag"></i> Orden de Pago
            </b-nav-item>
            <b-nav-item to="/ordenes" :active="$route.path === '/ordenes'">
              <i class="fa fa-list"></i> Órdenes Generadas
            </b-nav-item>
            <b-nav-item to="/reportes" :active="$route.path === '/reportes'">
              <i class="fa fa-ticket"></i> Reportes
            </b-nav-item>
          </b-navbar-nav>

          <b-navbar-nav class="me-auto" v-if="estaActivo && ($store.state.usuarioDB.rol==3) ">   
            <b-nav-item to="/pendientes" :active="$route.path === '/pendientes'">
              <i class="fa fa-copy"></i> Pendientes
            </b-nav-item>            
            <b-nav-item to="/facturacion" :active="$route.path === '/facturacion'">
              <i class="fa fa-credit-card"></i> Facturación
            </b-nav-item>
            <b-nav-item to="/reportes" :active="$route.path === '/reportes'">
              <i class="fa fa-ticket"></i> Reportes
            </b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav class="me-auto" v-if="estaActivo && ($store.state.usuarioDB.rol==4) ">     
            <b-nav-item to="/orden" :active="$route.path === '/orden'">
              <i class="fa fa-tag"></i> Orden de Pago
            </b-nav-item>
            <b-nav-item to="/ordenes" :active="$route.path === '/ordenes'">
              <i class="fa fa-list"></i> Órdenes Generadas
            </b-nav-item>  
            <b-nav-item to="/pendientes" :active="$route.path === '/pendientes'">
              <i class="fa fa-copy"></i> Pendientes
            </b-nav-item>    
            <b-nav-item to="/facturacion" :active="$route.path === '/facturacion'">
              <i class="fa fa-credit-card"></i> Facturación
            </b-nav-item>
            <b-nav-item to="/reportes" :active="$route.path === '/reportes'">
              <i class="fa fa-ticket"></i> Reportes
            </b-nav-item>
          </b-navbar-nav>
         
          <b-navbar-nav class="ml-auto" v-if="estaActivo">
            <b-nav-item-dropdown right >
              <template v-slot:button-content>
                <em>{{$store.state.usuarioDB.usuario}}</em>
              </template>
              <b-dropdown-item to="/perfil">Perfil</b-dropdown-item>
              <b-dropdown-item to="/cambiarPass">Cambiar Contraseña</b-dropdown-item>
              <b-dropdown-item to="/acerca">Acerca de</b-dropdown-item>
              <div class="dropdown-divider"></div>
              <b-dropdown-item @click="cerrarSesion()">Salir</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>

        </b-collapse>
      </nav>
    
    <slot />
    
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'Default',
  components:{
    
  },
  methods:{
    ...mapActions(['cerrarSesion', 'leerToken'])
  },
  computed:{
    ...mapGetters(['estaActivo']) 
  },
  created(){
    this.leerToken();
    this.title = process.env.VUE_APP_TITLE;
    this.icon = process.env.VUE_APP_ICON;
  }

};
</script>

<style scoped>
.fondo {
  position: absolute;
  min-height: 100%;
  min-width: 100%;  
  background-image: url("../assets/fondo.png") !important;
  background-repeat: repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #e7e7e9;
  margin-bottom: 100px;
}
</style>
